<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col-12 col-md-8">
        <h1 class="title-w1 mb-2">End-of-Life</h1>
        <p>
          <span class="text-bold">The End-of-Life (EoL)</span> tool aims to
          encourage more sustainable considerations once servers reach the end
          of its usable lifetime for a given user. 
        </p>
        <p>
          <span class="text-bold">Assess and compare</span> impacts
          and criticality risk of different end-of-life scenarios, including
          refurbishment, recycling using current industry methods, recycling
          using CEDaCI recommendations and landfill.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Compare reuse options</h3>
            <p class="card-text">
              Are you considering buying a new server to replace an existing
              product? Compare server models across social, economic, and
              environmental impacts and criticality risk.
            </p>
            <div class="btn-right">
              <router-link :to="{ name: 'Compare' }">
                <el-button type="primary">Go to Compare</el-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Compare the end-of-life options</h3>
            <p class="card-text">
              The server has reached the end of its first life. Compare the
              total impacts of various disposal options: refurbishment,
              recycling using CEDaCI recommendations, recycling with current
              industry methods and landfill.
            </p>
            <div class="btn-right">
              <router-link :to="{ name: 'CompareEoL' }">
                <el-button type="primary">Go to EOL</el-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EndOfLife',
};
</script>
