<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col-12 header-col">
        <h1 class="title-w1">End-of-Life</h1>
        <app-info title="End-of-Life">
          <p>
            This tool shows the best scenarios for the disposal of the equipment
            once a server reaches the end of its usable lifetime for a given
            user. Assess and compare the impacts and criticality risk of
            different end-of-life scenarios:
          </p>
          <p>
            <span class="text-bold">Refurbishment</span> refers to renovation of
            a previously used server, in the following configurations:
          </p>
          <ul>
            <li>
              <span class="text-bold">Default refurbishment:</span> All the PSUs
              and fans are replaced
            </li>
            <li>
              <span class="text-bold">Minimum Refurbishment:</span> All the
              PSUs, fans, and CPUs are replaced
            </li>
            <li>
              <span class="text-bold">Maximum Refurbishment:</span> All the
              PSUs, fans, CPUs, RAM, storage and expansion (not included in the
              tool) are replaced.
            </li>
          </ul>
          <p>
            <span class="text-bold">Conventional Recycling</span> is a scenario
            using presently available industry methods.
          </p>
          <p>
            <span class="text-bold">CEDaCI Recycling </span> refers to a new
            small-scale PCB recycling process developed during the project
            focused on the reclamation of CRMs: antimony and tantalum.
          </p>
          <p>
            <span class="text-bold">Landfilling</span> is included as a
            benchmark to highlight the benefits of refurbishment and recycling.
          </p>
        </app-info>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12"><h2 class="title-w3">Choose server:</h2></div>
      <div class="col-12 col-lg-10 mb-4">
        <server-card
          title="Server"
          @updateData="updateData"
          @reset="resetData"
        />
      </div>
    </div>
    <chart-legend :categories="categories" />
    <div class="row mb-2">
      <div class="btn-right select-wrapper">
        <el-select
          placeholder="Select a refurbishment scenario"
          v-model="selected"
          :disabled="!isConfigured"
        >
          <el-option
            v-for="option in options"
            :key="option.key"
            :value="option.key"
            :label="option.label"
          ></el-option>
        </el-select>
      </div>
      <div class="btn-right mt-2" v-if="errMessage?.length">
        <p class="text-bold fs-14 text-red">{{ errMessage }}</p>
      </div>
    </div>
    <EoLChart :chartData="chartData" v-if="chartData.length" />
  </div>
</template>

<script>
import EndOfLife from '@/utils/services/EndOfLife';
import Scores from '@/utils/services/Scores';

import EoLChart from '@/components/endoflife/EoLChart';
import ChartLegend from '@/components/endoflife/ChartLegend';
import ServerCard from '@/components/endoflife/ServerCard';

export default {
  name: 'CompareEoL',
  components: {
    ChartLegend,
    EoLChart,
    ServerCard,
  },
  data() {
    return {
      isConfigured: false,
      chartData: [],
      categories: [],
      server: {},
      config: {},
      options: [],
      selected: 'refurbishing_minimum',
      errMessage: '',
    };
  },
  created() {
    this.categories = EndOfLife.getDefaultCategories();
    this.options = EndOfLife.getRefurbishmentOptions();
    this.formatCategories();
  },
  watch: {
    selected(newVal, oldVal) {
      const { isValid, errMsg } = EndOfLife.validateRequirements(
        newVal,
        this.config
      );

      this.errMessage = isValid ? '' : errMsg;

      this.handleChange(newVal, oldVal);
    },
    isConfigured(newVal) {
      if (!newVal) {
        this.errMessage = '';
        this.selected = 'refurbishing_minimum';
      }
    },
  },
  methods: {
    resetData() {
      this.isConfigured = false;
      this.categories = EndOfLife.getDefaultCategories();
      this.config = {};
      this.formatCategories();
    },
    formatCategories() {
      this.chartData = this.categories.map((item) => {
        return {
          name: item.label,
          data: item.data,
        };
      });
    },
    updateData(server) {
      this.server = server;
      this.config = server.config;
      this.isConfigured = true;

      const { isValid, errMsg } = EndOfLife.validateRequirements(
        this.selected,
        server.config
      );

      this.errMessage = isValid ? '' : errMsg;

      const chartData = Scores.getEolScores(server, this.categories);
      this.chartData = [];
      this.$nextTick(() => {
        this.chartData = chartData;
      });
    },
    handleChange(newVal, oldVal) {
      this.categories = EndOfLife.updateCategories(
        this.categories,
        newVal,
        oldVal
      );
      this.updateData(this.server);
    },
  },
};
</script>

<style lang="scss" scoped>
.col-12.col-lg-10 {
  margin: 0 auto;
}

button {
  margin-left: 1rem;
}

ul {
  li {
    list-style: disc;
  }
}

.select-wrapper {
  .el-select {
    width: 40%;
  }
}
</style>
